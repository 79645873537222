// @ts-nocheck
import * as React from "react"
import {
    ChakraProvider,
    Box,
    Text,
    Link,
    VStack,
    Heading,
    theme,
    Container,
    Flex,
} from "@chakra-ui/react"
import { ColorModeSwitcher } from "./ColorModeSwitcher"
import { Logo } from "./Logo"

export const App = () => (
    <ChakraProvider theme={theme}>
        <Box textAlign="center" fontSize="xl">
            <div style={{ minHeight: "100vh", padding: "12px" }}>
                <Flex justifyContent="space-between" alignItems="center" py={4}>
                    <Logo />
                    <ColorModeSwitcher />
                </Flex>
                <Container maxW="container.lg" py={10}>
                    <VStack spacing={8}>
                        <Heading as="h1" size="2xl">
                            Steele Desmond
                        </Heading>
                        <Text fontSize="xl">
                            Welcome to the site.
                        </Text>
                    </VStack>
                </Container>
            </div>
        </Box>
    </ChakraProvider>
)
