import * as React from "react"
import { useColorMode } from "@chakra-ui/react"
// Import both logo variants
import logoDark from "./assets/logo-dark.svg"
import logoLight from "./assets/logo-light.svg"

export function Logo() {
  const { colorMode } = useColorMode()
  const logoSrc = colorMode === "dark" ? logoDark : logoLight
  
  // Using plain HTML attributes to avoid typing issues
  return React.createElement('img', {
    src: logoSrc,
    alt: "Logo",
    style: {
      height: "80px",
      width: "auto"
    }
  })
}
